import logo from "./logo.svg";
import "./App.css";
import RegistrationSuccess from "./components/TicketRegistration/RegistrationSuccess";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TicketRegistrationForm from "./components/TicketRegistration/Registration";
import ContestRegistrationForm from "./components/ContestRegistration/Registration";
import ContestRegistrationSuccess from "./components/ContestRegistration/RegistrationSuccess";

function App() {
  return (
    <Router basename={"/"}>
      <Routes>
        <Route
          path="/:id"
          element={<TicketRegistrationForm></TicketRegistrationForm>}
        />
        <Route
          path="gewinnspiel/:id"
          element={<ContestRegistrationForm></ContestRegistrationForm>}
        />
        <Route
          path="gewinnspiel/:id"
          element={<ContestRegistrationForm></ContestRegistrationForm>}
        />
        <Route
          path="gewinnspiel/success/:id"
          element={<ContestRegistrationSuccess></ContestRegistrationSuccess>}
        />
        <Route
          path="success/:id"
          element={<RegistrationSuccess></RegistrationSuccess>}
        />
      </Routes>
    </Router>
  );
}

export default App;
