export default function Footer() {
    return (
      <footer className="">
        <div className="max-w-2xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
          <nav
            className="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            {/* {navigation.main.map((item) => (
                  <div key={item.name} className="px-5 py-2">
                    <a
                      href={item.href}
                      className="text-base text-gray-500 hover:text-gray-900"
                    >
                      {item.name}
                    </a>
                  </div>
                ))} */}
            {/* <div key="Impressum" className="px-5 py-2">
              <a
                href={"/impressum"}
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Impressum
              </a>
            </div> */}
          </nav>
          <div className="mt-8 flex justify-center space-x-6">
            {/* {navigation.social.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))} */}
          </div>
          <p className="mt-8 text-center text-base text-gray-400">
          SAVOIR-VIVRE ist ein Produkt von:<br></br>
GO! PRESSEBÜRO & VERLAG GMBH<br></br>
Harvestehuder Weg 94<br></br>
D-20149 Hamburg<br></br>
<br></br>
Amtsgericht Hamburg HRB 82 924<br></br>
UID DE219986726<br></br>
SteuerNr. 42/725/0037<br></br>
FA Hamburg-Am Tierpark<br></br>
Geschäftsführer: Dipl.-Betriebswirt Karl-F. Lietz<br></br>
https://savoirvivre.de<br></br>
          </p>
        </div>
      </footer>
    );
  }