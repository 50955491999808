import { useNavigate } from "react-router-dom";
import React from "react";
import { Disclosure } from "@headlessui/react";
import Footer from "../Footer";
import { useParams } from "react-router";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RegistrationSuccess() {
  const navigate = useNavigate();
  const { id } = useParams();
  const navBack = async () => {
    navigate("/" + id);
  };
  return (
    <>
      <div className="min-h-full">
        <div className="bg-primarycol-600 pb-32">
          <Disclosure
            as="nav"
            className="bg-primarycol-600 border-b border-primarycol-300 border-opacity-25 lg:border-none"
          >
            {({ open }) => (
              <>
                <div className="max-w-2xl mx-auto px-2 sm:px-4 lg:px-8">
                  <div className=" h-16 flex items-center justify-between lg:border-b lg:border-primarycol-400 lg:border-opacity-25">
                    <div className="px-2 flex items-center lg:px-0">
                      <div className="flex-shrink-0">
                        <img
                          className="lg:block h-8 w-auto"
                          src={"../../Logo-Einzeilig-weiss.png"}
                          alt="Workflow"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Disclosure>
          <header className="py-10">
            <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold text-white">
                Ticket Registrierung
              </h1>
            </div>
          </header>
        </div>

        <main className="-mt-32">
          <div className="max-w-2xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
            <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
              <div className="pt-8 space-y-6 sm:pt-1 sm:space-y-5">
                <div>
                  <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      width="24px"
                      height="24px"
                      class="h-5 w-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Anmeldung erfolgreich
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Sie erhalten Ihre Eintrittskarte per E-Mail. Zum Check-In
                    zeigen Sie einfach Ihren QR-Code aus der E-Mail beim Einlass
                    vor.
                  </p>
                  <p>
                    Weitere Informationen zur Anreise auch unter
                    www.savoirvivre.de.
                    <br></br>
                    <br></br>
                    Bei Fragen erreichen Sie uns unter<br></br>
                    messe@savoirvivre.de oder<br></br>+ 49 (0) 40 45 03 84 10
                    <br></br>
                  </p>
                </div>
              </div>
              <br></br>
              <button
                onClick={navBack}
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Weitere Tickets bestellen
              </button>
            </div>
          </div>
          <Footer></Footer>
        </main>
      </div>
      )
    </>
  );
}
