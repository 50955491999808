import React from "react";
import { Disclosure } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { db } from "../../firebase";
import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Footer from "../Footer";
import getCountFromServer from "firebase/app";

// import Footer from "../../page/Footer/Footer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ContestRegistrationForm() {
  const [show, setShow] = useState(false);
  const [showEmailTooOften, setShowEmailTooOften] = useState(false);
  const navigate = useNavigate();

  const [vorname, setVorname] = useState("");
  const [anrede, setAnrede] = useState("Frau");
  const [nachname, setNachname] = useState("");
  const [email, setEmail] = useState("");
  const [land, setLand] = useState("");
  const [str, setStr] = useState("");
  const [ort, setOrt] = useState("");
  const [plz, setPlz] = useState("");
  const { id } = useParams();

  const addTodo = async () => {
    var currentTime = new Date();
    var hours = currentTime.getHours();
    var minutes = currentTime.getMinutes();
    var seconds = currentTime.getSeconds();
    var day = currentTime.getDate();
    var month = currentTime.getMonth() + 1;
    var year = currentTime.getFullYear();
    try {
      const data = {
        anrede: anrede,
        vorname: vorname,
        nachname: nachname,
        email: email,
        land: land,
        str: str,
        ort: ort,
        plz: plz,
        location: id,
        date:
          day +
          "/" +
          month +
          "/" +
          year +
          " " +
          hours +
          ":" +
          minutes +
          ":" +
          seconds,
      };
      if (
        data.nachname.length > 0 &&
        data.vorname.length > 0 &&
        data.email.length > 0
      ) {
        const res = await db.collection("gewinnspiel").add(data);

        // sendmail(email, event);
        navigate("/gewinnspiel/success/" + id);
      } else {
        setShow(true);
      }
    } catch (e) {
      console.error("Error adding user: ", e);
    }
  };

  return (
    <>
      <div className="min-h-full">
        <div className="bg-primarycol-600 pb-32">
          <Disclosure
            as="nav"
            className="bg-primarycol-600 border-b border-primarycol-300 border-opacity-25 lg:border-none"
          >
            {({ open }) => (
              <>
                <div className="max-w-2xl mx-auto px-2 sm:px-4 lg:px-8">
                  <div className=" h-16 flex items-center justify-between lg:border-b lg:border-primarycol-400 lg:border-opacity-25">
                    <div className="px-2 flex items-center lg:px-0">
                      <img
                        className="lg:block h-8 w-auto"
                        src={"../../Logo-Einzeilig-weiss.png"}
                        alt="Workflow"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </Disclosure>
          <header className="py-10">
            <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold text-white">Gewinnspiel</h1>
            </div>
          </header>
        </div>

        <main className="-mt-32">
          <div className="max-w-2xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
            <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
              <div className="pt-8 space-y-6 sm:pt-1 sm:space-y-5">
                <div>
                  <p className="mt-1 max-w-2xl text-base text-gray-500">
                    Wir freuen uns, dass Sie sich entschieden haben, an unserem
                    Gewinnspiel teilzunehmen. <br></br>Im Falle eines Gewinns
                    werden Sie per E-Mail benachrichtigt. Bitte überprüfen Sie,
                    ob die von Ihnen angegebene E-Mail-Adresse korrekt ist,
                    damit wir Sie reibungslos informieren können.
                    <br></br>
                    Mit Ihrer Teilnahme erklären Sie sich auch damit
                    einverstanden, dass wir Sie per E-Mail über mögliche Gewinne
                    sowie relevante Veranstaltungen informieren dürfen.
                  </p>
                  {/* <div class="border text-sm border-gray-300 py-2 px-4 mt-8 text-center rounded-md max-w mx-auto">
                      {event.name}
                    </div>
                    <div
                      class="border text-sm border-gray-300 py-2 px-4 mt-4 text-center rounded-md max-w mx-auto"
                      dangerouslySetInnerHTML={{ __html: event.adresse }}
                    ></div>
                    <div
                      class="border text-sm border-gray-300 py-2 px-4 mt-3 text-center rounded-md max-w mx-auto"
                      dangerouslySetInnerHTML={{ __html: event.datum }}
                    ></div> */}
                </div>
                <div className="space-y-6 sm:space-y-5 pt-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Persönliche Informationen
                  </h3>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="anrede"
                      className="block text-base font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Anrede
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        id="anrede"
                        name="anrede"
                        className="max-w-lg block w-full shadow-sm focus:ring-primarycol-500 focus:border-primarycol-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        defaultValue="Frau"
                        onChange={(e) => setAnrede(e.target.value)}
                      >
                        <option key="Frau" value="Frau">
                          Frau
                        </option>
                        <option key="Herr" value="Herr">
                          Herr
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="first-name"
                      className="block text-base font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Vorname
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        required
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        onChange={(e) => setVorname(e.target.value)}
                        className="max-w-lg block w-full shadow-sm focus:ring-primarycol-500 focus:border-primarycol-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="last-name"
                      className="block text-base font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Nachname
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        required
                        type="text"
                        name="last-name"
                        id="last-name"
                        onChange={(e) => setNachname(e.target.value)}
                        autoComplete="family-name"
                        className="max-w-lg block w-full shadow-sm focus:ring-primarycol-500 focus:border-primarycol-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="email"
                      className="block text-base font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Email Adresse
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        required
                        id="email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        autoComplete="email"
                        className="max-w-lg block w-full shadow-sm focus:ring-primarycol-500 focus:border-primarycol-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="street-address"
                      className="block text-base font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Straße, Nr.
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="street-address"
                        onChange={(e) => setStr(e.target.value)}
                        id="street-address"
                        autoComplete="street-address"
                        className="max-w-lg block w-full shadow-sm focus:ring-primarycol-500 focus:border-primarycol-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="postal-code"
                      className="block text-base font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      PLZ
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="postal-code"
                        onChange={(e) => setPlz(e.target.value)}
                        id="postal-code"
                        minLength={2}
                        autoComplete="postal-code"
                        className="max-w-lg block w-full shadow-sm focus:ring-primarycol-500 focus:border-primarycol-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Ort
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="city"
                        onChange={(e) => setOrt(e.target.value)}
                        id="city"
                        autoComplete="address-level2"
                        className="max-w-lg block w-full shadow-sm focus:ring-primarycol-500 focus:border-primarycol-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="last-name"
                      className="block text-base font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Land
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="country"
                        onChange={(e) => setLand(e.target.value)}
                        id="country"
                        autoComplete="country-name"
                        className="max-w-lg block w-full shadow-sm focus:ring-primarycol-500 focus:border-primarycol-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"></div>
                  <div className="pt-5">
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        onClick={addTodo}
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primarycol-600 hover:bg-primarycol-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primarycol-500"
                      >
                        Registrieren
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </main>
      </div>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">Fehler</p>
                    <p className="mt-1 text-sm text-gray-500">
                      Bitte füllen Sie alle Felder aus!
                    </p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={showEmailTooOften}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">Fehler</p>
                    <p className="mt-1 text-sm text-gray-500">
                      Diese E-Mail adresse wurde bereits verwendet.
                    </p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowEmailTooOften(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
